.default-popup.message-proudct-popup {
  height: unset;
  align-items: flex-end;

  .primary-gallery {
    height: 300px;
    padding: 1rem;

    .product-item {
      width: 120px;
      height: 120px;
      position: sticky;

      .ad-product-info {
        position: absolute;
        bottom: 0;
        padding: 0.25rem 0.25rem 0.1rem 0.25rem;

        .user-info {
          display: none;
        }
      }
    }
  }

  .product-item.selected {
    outline: 3px solid var(--bs-primary);
  }

  .accept-btn {
    margin-top: 1.5rem;
  }

  .thumbnail-wrapper {
    padding-top: 100%;
    width: unset;
    height: unset;

    .thumbnail {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}