.support-company-section {

  .primary-gallery-wrapper {
    row-gap: 50px;

    .agency-item {
      .thumbnail-wrapper {
        height: 180px;
      }
    }
  }
}