.product-management-section {
  display: flex;
  justify-content: center;

  .product-management-wrapper {
    height: 80%;

  }

  .product-management-table-wrapper {
    height: 80%;
    background-color: white;
    border: 1px solid var(--bs-gray, #D9D9D9);
    background: #FFF;

    .product-management-table {
      width: 100%;

      button+button {
        margin-left: 10px;
      }

      th,
      td {
        border: 1px solid #D9D9D9;
      }

      td:nth-child(1) {
        width: 7.0211%;
      }

      td:nth-child(2) {
        width: 22.8253%;
      }

      td:nth-child(3) {
        width: 14.3953%;
        padding: 0 13px;
      }

      td:nth-child(4) {
        width: 14.3953%;
      }

      td:nth-child(5) {
        width: 29.5585%;
      }

      td:nth-child(6) {
        width: 11.8045%;
      }

      tr {
        td {
          height: 50px;
          text-align: center;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        th {
          height: 30px;
          text-align: center;
          border-top: none;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

    }
  }


  .content-region {
    width: 88.581024349%;
  }
}

.product-create-section {
  display: flex;
  justify-content: center;

  .content-title-wrapper {
    color: var(--bs-black, #222);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.56px;
    margin: 0.5rem 0 1rem 0;
  }

  .content-region {
    width: 88.581024349%;

    .form-label span {
      display: block;
    }

    .title,
    .form-label span {
      color: #222;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0.5rem 0;
    }    

    .image-input {
      width: 100%;
      height: calc(100% - 16px - 2rem);

      .thumbnail-wrapper {
        height: 100%;
      }
    }
  }
}