.product-popup {
  width: 781px;
  height: 853px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  p {
    padding: 0;
    margin: 0;
  }

  .content {
    width: 100%;
    height: 700px;

    overflow-y: auto;

    border-radius: 10px;
    box-shadow: 0px 4px 18px 7px rgba(0, 0, 0, 0.10);
    display: block;

    img {
      width: 100%;
      height: auto;
    }
  }

  .info-wrapper {
    display: flex;
    width: 100%;
    height: 118px;
    padding: 1rem 1rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);

    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.32px;

    .info {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }

    .property {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.4px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      >span {

        &::after {
          content: "|";
          padding: 0 1rem;
        }

        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.32px;
      }
    }
  }

  .accept-btn {
    width: 8rem;
    height: 100%;
  }
}