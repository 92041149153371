.request-quote-section {
  width: 80%;
  margin: 0 auto;
  padding-top: 2.5rem;

  .content-title-wrapper {
    color: var(--bs-primary);
    font-size: 2rem;
    font-weight: 600;
  }

  form {
    margin-top: 1.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
    row-gap: 1.5rem;

    .ad-resource {
      grid-column: 2;

      grid-row-start: 3;
      grid-row-end: 6;

      .image-input {
        width: 100%;
        height: 85%;

        .thumbnail-wrapper {
          height: 100%;
        }
      }
    }

    .input-title {
      color: #222;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0.5rem;
    }

    .input-group {
      input {
        display: flex;
        padding: 22px 13px;
        align-items: center;
        gap: 10px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .submit-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: right;

      .btn {
        padding: 0.5rem 3rem;
      }
    }
  }
}