@media (max-width: 576px) {
  html {
    font-size: 12px;
  }
}

.content-slider {
  overflow-x: hidden;
}

.circle-thumbnail-item {
  .thumbnail-wrapper {
    border-radius: 100%;
  }
}

.paginations {
  .btn {
    border: none;
    padding: 0;
    margin: 0;
    opacity: initial;
  }

  .page-index {
    color: #99B49F;
    font-family: Acme;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 9.6px;

    &.active {
      color: #127718;
      opacity: initial;
    }
  }
}

.file-input {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 17px 13px;
  align-items: center;
  gap: 10px;

  .file-open-btn {
    border-radius: 5px;
    background: var(--hue2-gray, #D9D9D9);
    display: flex;
    min-width: 98px;
    height: 35px;
    padding: 0px 10px;
    align-items: center;
    gap: 10px;

    color: var(--bs-white, #FFF);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input[type=file] {
    display: none;
  }
}

.short-cuts {
  --btn-width: 87px;
  --btn-height: 87px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 3.5%;
  bottom: 5%;
  height: calc(2 * var(--btn-height) + 2rem);

  .sticky-btn {
    transform: translateY(-50%);
    z-index: 1000;
    width: var(--btn-width);
    height: var(--btn-height);
    cursor: pointer;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .kakao {
    background-color: #FFE812;
    border: 3px solid #FFF;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: center;
    align-items: center;

    .kakao-icon {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}

.popup-area {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: sticky;

  .close-btn {
    position: absolute;
    right: 1.5rem;
    top: 1rem;

    width: 40px;
    height: 40px;
    padding: 0;
  }
}

.empty-content {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 250px;  

  color: var(--bs-gray, #D9D9D9);
  font-size: 1.5rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.48px;
  border: 1px solid var(--bs-gray, #E7E7E7);

  svg {
    width: 61px;
    height: 61px;

    margin-bottom: 1rem;
  }
}

.pe-pointer {
  cursor: pointer;
}

form.product-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
  column-gap: 2rem;

  .etc {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .request {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .thumbnail {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
  }

  .detail {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;

    min-height: 280px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    grid-column-start: 1;
    grid-column-end: 3;

    .btn {
      padding: 0.5rem 2.5rem;
    }
  }

  .form-label {
    width: 100%;
  }

  .form-label span {
    display: block;
  }

  .title,
  .form-label span {
    color: #222;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0.5rem 0;
  }    

  .image-input {
    width: 100%;
    height: calc(100% - 16px - 2rem);

    .thumbnail-wrapper {
      height: 100%;
    }
  }
}