.matching-section {
  display: flex;
  justify-content: center;

  .content-region {
    width: 100%;

    .calendar-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .calendar {
        max-width: 1080px;
        width: 75%;
        margin-left: 3%;
        margin-right: 3%;
        
        .today {
          position: sticky;
          color: white;

          &::after {
            position: absolute;
            content: " ";
            background-color: var(--bs-primary, #4E9A5E);
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -50%);
            z-index: -1;
          }
        }
      }
    }
  }
}