.primary-gallery-wrapper {
  .primary-gallery {
    row-gap: 15px;
    overflow-y: hidden;

    .primary-gallery-item {
      width: 180px;

      .thumbnail-wrapper {
        height: 180px;

        border-radius: 10px;
      }

      .info {
        width: inherit;

        .description {
          width: 100%;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }

    .primary-gallery-info {
      line-height: 1.3rem;
    }
  }

  .product-item {
    position: sticky;

    .info {
      position: absolute;
      bottom: 0;
      padding: 0.5rem;

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: white;

        text-shadow: rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px;
      }

      .profile-icon {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;

        .thumbnail-wrapper {
          width: inherit;
          height: inherit;
          border-radius: inherit;
        }
      }

      .user-info {
        display: flex;
        align-items: center;

        .user-name {
          text-shadow: rgb(34, 34, 34) 0px 0px 1px, rgb(34, 34, 34) 0px 0px 1px, rgb(34, 34, 34) 0px 0px 1px, rgb(34, 34, 34) 0px 0px 1px, rgb(34, 34, 34) 0px 0px 1px;
        }
      }
    }
  }
}

.main-layout {
  --nav-region-width: 250px;
  --header-height: 130px;

  a {
    text-decoration: none;
  }

  .primary-speech-bubble {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.5rem;

    color: var(--bs-primary, #4E9A5E);
    border-radius: 10px;
    border: 2px solid var(--bs-primary, #4E9A5E);
    background: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(78, 154, 94, 0.20);

    font-size: 1.75rem;

    p {
      padding: 0;
      margin: 0;

      svg {
        margin-right: 1rem;
      }
    }
  }

  .nav-region {
    --region-width: 250px;
    --bs-text-opacity: 1;

    width: var(--region-width);
    min-height: 100vh;

    border-right: 1px solid var(--bs-gray, #E7E7E7);

    .logo-wrapper {
      height: var(--header-height);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav {
      a {
        text-decoration: none;
      }

      .nav-item {
        padding: 1rem;
        padding-left: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity));
        fill: var(--bs-gray);

        &.active {
          fill: var(--bs-primary);
          color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
        }

        svg {
          fill: inherit;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .content-body {
    width: calc(100vw - var(--nav-region-width));
  }

  .header {
    width: 91.0999160369437447523%;
    margin: 0 auto;
    max-width: calc(100vw - var(--nav-region-width));
    height: var(--header-height);

    display: flex;
    justify-content: space-between;
    align-items: center;


  }

  .profile-icon {
    width: 3.375rem;
    height: 3.375rem;
    border-radius: 100%;

    .thumbnail-wrapper {
      border-radius: 100%;
    }
  }

  .main-section {
    border-top: 1px solid var(--bs-gray, #E7E7E7);
    min-height: calc(100vh - var(--header-height) - 3rem);
    background-color: #FCFDFD;
    padding-bottom: 3rem;
  }

  .header-nav {
    justify-content: space-between;
    align-items: center;

    *+* {
      margin-left: 1rem;
    }
  }

  .form-label {
    width: 100%;
  }


  .required::after {
    content: "*";
    color: var(--bs-red, #FF5F5F);
    font-weight: 500;
    line-height: normal;
  }

  .pay-table {
    --font-size: 1.5rem;
    --bs-table-bg: white;
    border-color: var(--bs-gray);

    th,
    td {
      font-size: var(--font-size);
      color: var(--bs-gray);
    }

    .empty-body {
      td {
        height: calc(var(--font-size) * 6);
        justify-content: center;
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  .calendar {
    background-color: white;
    border-radius: 10px;
    border: 1px solid var(--bs-gray, #D9D9D9);
    padding: 2.5rem 2.75rem;

    .react-calendar__navigation__arrow {
      display: none;
    }

    .react-calendar__month-view__days__day {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .react-calendar__month-view__weekdays__weekday {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;

      abbr {
        text-decoration: none;
        cursor: default;

      }
    }

    .react-calendar__month-view__weekdays__weekday--weekend,
    .react-calendar__month-view__days__day--weekend {
      color: var(--bs-red, #FF5F5F);
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: var(--bs-gray, #E7E7E7);
    }

    .react-calendar__navigation__label__labelText--from {
      color: var(--bs-black, #222);
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      pointer-events: none;
    }


    button {
      border: none;
      background-color: transparent;
    }
  }

  .content-wrapper {
    .content-title-wrapper {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: var(--bs-font-size-3);
    }
  }

  .thumbnail-wrapper {
    .thumbnail {
      background-size: cover;
      background-position: center;
    }
  }

  .image-input {
    border-radius: 10px;
    border: 1px solid #C6C7CA;
    background: #FFF;

    .image-input-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  .row {
    margin-right: initial;
  }
}


.pointer {
  cursor: pointer;
}