.account-page {

  .main-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .account-form {
      background-color: white;
      border: 1px solid #C6C7CA;
      padding: 1.5rem;
      width: 100%;

      .title {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0.5rem 0;
      }

      .image-input {
        width: 475px;
        height: 336px;
      }

      input {
        border-radius: 10px;
        border: 1px solid #C6C7CA;

        background: #FFF;
        padding: 0.5rem 1rem;

        &::placeholder {
          color: #C6C7CA;

        }
      }
    }
  }


}