@use "sass:map";

.identity-layout {
    padding: 3.0546875% 0;
    justify-content: center;
    align-items: center;


    .page-content {
        align-items: center;
        width: 466px;
        min-height: 40vh;
        justify-content: space-between;
    }

    a {
        text-decoration: none;
    }

    .btn-secondary {
        $bs-btn-color: #C8C8C8;

        --bs-btn-color: #fff;
        --bs-btn-bg: #{$bs-btn-color};
        --bs-btn-border-color:#{$bs-btn-color};
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #{darken($bs-btn-color, 5%)};
        --bs-btn-hover-border-color: #{darken($bs-btn-color, 10%)};
        --bs-btn-focus-shadow-rgb: 49, 132, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #{darken($bs-btn-color, 10%)};
        --bs-btn-active-border-color: #{darken($bs-btn-color, 15%)};
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #{$bs-btn-color};
        --bs-btn-disabled-border-color: #{$bs-btn-color};
    }

    .btn-outline-secondary {
        $bs-btn-color: #C8C8C8;

        --bs-btn-color: #{$bs-btn-color};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: #{$bs-btn-color};
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #4E9A5E;
        --bs-btn-hover-border-color: #4E9A5E;
        --bs-btn-focus-shadow-rgb: 13, 110, 253;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #4E9A5E;
        --bs-btn-active-border-color: #4E9A5E;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #{$bs-btn-color};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #{$bs-btn-color};
        --bs-gradient: none;
    }

    .identity-logo {
        width: 264px;
        height: 50px;
    }

    .identity-content-wrapper,
    .input-group-wrapper {
        display: flex;
        flex-direction: column;
    }

    .identity-content-wrapper {
        width: 100%;
    }

    .input-group-wrapper {
        input {
            border-radius: 5px;
            height: 3.75rem;
        }

        label {
            color: #C8C8C8;
            letter-spacing: -0.44px;
        }

        .pw-input {
            border-right: none;
        }

        .eye-icon {
            border-left: none;
        }
    }

    .btn-secondary {
        &:hover {
            .icon {
                fill: #fff;
            }
        }
    }

    .active {
        .icon {
            fill: #fff;
        }
    }

    .icon {
        fill: #C8C8C8;
    }

    .social-login-btn {
        position: sticky;

        .login-icon {
            position: absolute;
            left: 15px;
            width: 2rem;
            height: 2rem;
        }
    }

    .text-info a {
        color: var(--bs-info);
    }

    form {
        width: 100%;
        display: grid;
    }

    .question-mark {
        position: sticky;
        padding-left: 1.2em;

        &::before {
            position: absolute;
            left: 0;
            top: 4px;
            line-height: normal;

            content: " ";
            background: url('../../../public/imgs/circleExclamation.svg');
            background-size: cover;
            background-position: center;
            width: 1em;
            height: 1em;

            fill: red;
        }
    }
}