.showhost-profile-container {
  --badge-font-weight: 400;
  --bg-gray-color: #222;

  display: flex;
  justify-content: center;

  .badge {
    font-weight: var(--badge-font-weight);
  }

  .bg-gray {
    color: var(--bg-gray-color);
  }

  .broadcast-time {
    color: #D9D9D9;
  }

  .content-region {
    width: 88.581024349%;
    justify-content: space-between;

    .profile-content-wrapper {
      width: 31.4814%;
      height: fit-content;
      box-shadow: 0px 9px 21px -6px rgba(0, 0, 0, 0.25);

      .profile-image-wrapper {
        padding-top: 135.294117647%;
        width: 100%;

        .profile-image {
          width: 100%;
          height: 460px;
        }

        .link-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }

      .profile-content {
        padding: 20px 13px;

        a {
          color: #222;
        }

        .consulting-btn {
          width: 100%;
          height: 54px;
        }
      }
    }

    .info-content-wrapper {
      width: 63.8888%;

      .info-content {
        border-top: 2px solid #4E9A5E;
        box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.10);
        padding: 46px 51px;

        .subtitle {
          height: max-content;
        }

        .portfolio-video-item {
          --per: 1.4;
          width: calc(160px * var(--per));
          height: calc(90px * var(--per));
          ;
          background-color: black;
        }

        .star-mask {
          mask-image: url(../../../public/imgs/starGroupIcon.svg);
          mask-repeat: no-repeat;
          width: 5rem;
          height: 1rem;
          background-color: var(--bs-primary);
        }

        .star-fill-mask {
          mask-image: url(../../../public/imgs/solidStarGroupIcon.svg);
          mask-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
      }
    }

    .icon {
      width: 14px;
      height: 14px;
    }
  }

  .profile-image-wrapper {
    position: sticky;

    .thumbnail-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;
    }

  }
}