.main-visual-form.container {
  max-width: 1920px;
  padding: 0;

  .banner-preview-wrapper {
    max-width: 1920px;
    max-height: 980px;

    position: sticky;

    width: 100%;
    padding-top: 980px;

    .banner-preview {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .image-input {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}