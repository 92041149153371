.recommend-section {
  display: flex;
  align-items: center;
  flex-direction: column;

  .recommend-content-wrapper {
    width: 67.96875vw;

    .search-bar-wrapper {
      display: flex;
      justify-content: flex-end;

      .search-bar {
        width: 27.95969773299748%;
      }
    }
  }
}