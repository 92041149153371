@use "sass:map";

.advertiser-dashboard-section {
  display: flex;
  justify-content: center;

  .product-item {
    cursor: pointer;
  }

  .content-region {
    width: 88.581024349%;
  }

  .live-commerce-slider {
    .live-commerce-item {
      .thumbnail-wrapper {
        width: 169px;
        height: 256px;

        border-radius: 10px;
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .live-commerce-item+.live-commerce-item {
    margin-left: 2rem;
  }

  .product-item {
    position: sticky;

    .thumbnail-wrapper {
      width: 180px;
      height: 180px;
      border-radius: 10px;
    }

    .info {
      position: absolute;
      bottom: 0.5rem;
      left: 0.3rem;
      color: white;
      text-shadow: rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px, rgb(34, 34, 34) 0px 0px 1.97188px;

      display: flex;
      width: 161px;
      height: 44px;
      flex-direction: column;
      justify-content: flex-end;

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .product-item+.product-item {
    margin-left: 1rem;
  }

  .advertiser-section {
    --content-height: 25rem;
    justify-content: space-between;
    align-items: center;

    >* {
      width: 40%;
    }

    .new-host-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: var(--content-height);
      width: 100%;

      .new-host-content {
        display: flex;
        justify-content: center;
        width: 90%;
        text-align: center;

        .new-host-item {
          width: 8rem;
          height: 8rem;
        }
      }
    }

    .talking-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: var(--content-height);
      width: 100%;
      border: 1px solid var(--bs-gray);
      background: var(--bs-white);
      padding: 1.5rem 2rem;

      .talking-host-content {
        width: 100%;
        height: 100%;

        .talking-host-item {
          width: 8rem;
          height: 8rem;
        }
      }
    }

    .link {
      color: #127718;
    }
  }

  .lf-auto-resize-gallery-wrapper {
    height: 100%;
    width: 100%;

    text-align: center;
  }
}