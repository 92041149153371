.lf-auto-resize-gallery-wrapper {
  .lf-auto-resize-gallery {
    display: grid;
    justify-content: space-between;
    overflow-x: hidden;
  }

  .paginations {
    display: flex;
    justify-content: center;
    align-items: center;
    > * + * {
      margin-left: 1rem;
    }
  }
}