@use 'sass:color';

:root {
  --bs-body-font-family: 'Pretendard', var(--bs-font-sans-serif);
}

.btn-primary {
  $bs-btn-color: #4E9A5E;

  --bs-btn-color: #fff;
  --bs-btn-bg: #{$bs-btn-color};
  --bs-btn-border-color:#{$bs-btn-color};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{darken($bs-btn-color, 5%)};
  --bs-btn-hover-border-color: #{darken($bs-btn-color, 10%)};
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{darken($bs-btn-color, 10%)};
  --bs-btn-active-border-color: #{darken($bs-btn-color, 15%)};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #{$bs-btn-color};
  --bs-btn-disabled-border-color: #{$bs-btn-color};
}

.btn-outline-primary {
  $bs-btn-color: #4E9A5E;

  --bs-btn-color: #{$bs-btn-color};
  --bs-btn-border-color: #{$bs-btn-color};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{$bs-btn-color};
  --bs-btn-hover-border-color: #{$bs-btn-color};
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{$bs-btn-color};
  --bs-btn-active-border-color: #{$bs-btn-color};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{$bs-btn-color};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{$bs-btn-color};
  --bs-gradient: none;
}

.text-info {
  fill: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.vertical-split-line {
  --vertical-split-line-before-font-weight: 400;
  --vertical-split-line-before-line-height: .9em;
  --vertical-split-line-before-margin: 0 0.5em;
  --vertical-split-line-color: inherit;

  *+*:not(.vertical-split-line * > *):before {
    content: "|";
    font-weight: var(--vertical-split-line-before-font-weight);
    line-height: var(--vertical-split-line-before-line-height);
    margin: var(--vertical-split-line-before-margin);
    color: var(--vertical-split-line-color);
  }
}

.btn-outline-secondary {
  $bs-btn-color: #dee2e6;

  --bs-btn-color: #{$bs-btn-color};
  --bs-btn-border-color: #{$bs-btn-color};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #{$bs-btn-color};
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #{$bs-btn-color};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{$bs-btn-color};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{$bs-btn-color};
  --bs-gradient: none;
}

.btn-naver {
  $bs-btn-color: #03C75A;

  --bs-btn-color: #fff;
  --bs-btn-bg: #{$bs-btn-color};
  --bs-btn-border-color:#{$bs-btn-color};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{darken($bs-btn-color, 5%)};
  --bs-btn-hover-border-color: #{darken($bs-btn-color, 10%)};
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{darken($bs-btn-color, 10%)};
  --bs-btn-active-border-color: #{darken($bs-btn-color, 15%)};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #{$bs-btn-color};
  --bs-btn-disabled-border-color: #{$bs-btn-color};
}

.btn-kakao {
  $bs-btn-color: #FEE500;

  --bs-btn-color: #181600;
  --bs-btn-bg: #{$bs-btn-color};
  --bs-btn-border-color:#{$bs-btn-color};
  --bs-btn-hover-color: #181600;
  --bs-btn-hover-bg: #{darken($bs-btn-color, 5%)};
  --bs-btn-hover-border-color: #{darken($bs-btn-color, 10%)};
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #181600;
  --bs-btn-active-bg: #{darken($bs-btn-color, 10%)};
  --bs-btn-active-border-color: #{darken($bs-btn-color, 15%)};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #181600;
  --bs-btn-disabled-bg: #{$bs-btn-color};
  --bs-btn-disabled-border-color: #{$bs-btn-color};
}

.btn-google {
  $bs-btn-color: #222;
  $bs-btn-border-color: #E7E7E7;

  --bs-btn-color: #{$bs-btn-color};
  --bs-btn-border-color: #{$bs-btn-border-color};
  --bs-btn-hover-color: #222;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #{$bs-btn-border-color};
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #222;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #{$bs-btn-border-color};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{$bs-btn-color};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{$bs-btn-border-color};
  --bs-gradient: none;
}

.file-input {
  display: flex;

  input[type=file] {
    display: none;
  }
}