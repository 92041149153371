.back-account-section {
  display: flex;
  justify-content: center;
  align-items: center;

  .content-region {
    width: 86.722689%;

    input {
      display: flex;
      padding: 22px 13px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }
  }
}