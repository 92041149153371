.manager-layout {
  --header-height: 72px;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;

  .manager-nav {
    min-height: 100vh;
    background-color: #353E4F;
    width: 265px;

    .logol-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;

    }

    * {
      color: #FFF;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      text-decoration: none;
    }

    ul.nav {
      padding: 1rem;

      svg {
        margin-right: 0.5rem;
        width: 14px;
        height: 14px;
      }

      &>* {
        margin: 0.5rem;

        &>ul {
          &>* {
            margin: 0.5rem;
            display: block;
          }
        }
      }
    }
  }

  header {
    height: 70px;
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
  }

  main.dashboard {
    .container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      height: 100%;
    }

    canvas {
      height: 230px;
    }

    .title {
      color: #000;

      font-size: 1.1rem;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
    }

    .info-card {
      --color: #00A29A;
      font-size: 1.5rem;
      font-weight: bold;

      .value {
        margin-top: 0.5rem;
        color: var(--color);
        border-bottom: 5px solid var(--color);
      }
    }

    .info-card.dark-lime-green {
      --color: #22ac38;
    }

    .info-card.strong-green {
      --color: #8fc31f;
    }

    .info-card.bright-blue {
      --color: #2ea7e0;
    }
  }

  .table {
    text-align: center;

    td,
    th {
      align-items: center;
      vertical-align: middle;
    }
  }

  .content-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}