.user-edit-section {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-label {
    width: 100%;
  }

  .file-input {
    padding: 0;
  }
}