.lf-image-slider {
  --button-spacing: 2.5rem;
  position: sticky;

  .lf-image-slider-content {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .lf-image-slider-item-content {
    width: 100%;
    height: 100%;
  }

  .lf-image-slider-bg {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    display: flex;
  }

  .lf-image-slider-item {  
    flex: 0 0 100%; /* can't grow, can't shrink, fixed at 290px */
    height: 100%;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .button {
    position: absolute;
    cursor: pointer;
  }

  .left-button {
    left: var(--button-spacing);
    top: 50%;
    transform: translateY(-50%);
  }

  .right-button {
    right: var(--button-spacing);
    top: 50%;
    transform: translateY(-50%);
  }
}