.message-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0100%;

  .content-region {
    width: 90%;
    height: calc(90vh - var(--header-height));

    border-radius: 30px;
    border: 1px solid var(--bs-primary, #4E9A5E);
    background: #FFF;
    box-shadow: 0px 4px 16px -6px rgba(78, 154, 94, 0.30);

    display: flex;
    justify-content: space-between;

    overflow: hidden;

    .users-wrapper {
      width: 30%;
      min-width: 330px;

      border-right: 1px solid var(--bs-grey, #E7E7E7);

      .speech-balloon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        color: var(--bs-primary);
        padding-top: 30px;
        height: 22.5%;

        font-size: var(--bs-font-size-2, 2rem);
        font-weight: 300;

        svg {
          width: 3rem;
          height: 3rem;
        }
      }


      .user-message-label-wrapper {
        min-height: 64px;
        padding: 0.5rem;
        margin: 0.5rem;

        align-items: center;


        .profile-img-wrapper {
          position: sticky;
          width: 15%;
          min-width: 64px;
          margin-right: 1rem;
        }

        .info {
          color: #D9D9D9;
        }

      }

      .user-message-label-wrapper.active {
        background-color: #f3f3f3;
      }


      .users-mesaage-list {
        height: calc(100% - 22.5%);
        overflow-y: auto;
      }
    }


    .message-list-wrapper {
      height: 100%;
      width: 70%;

      .profile-icon-wrapper {
        position: sticky;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
      }
    }
  }

  .profile-img {
    padding-top: 100%;
    position: sticky;
    width: 100%;

    .thumbnail-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 100%;

      .thumbnail {
        width: 100%;
        height: 100%;
      }
    }
  }

  .message-room {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .message-rome-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--bs-grey, #E7E7E7);
      padding: 0.5rem 2rem 0.5rem 1rem;

      .profile {
        display: flex;
        align-items: center;
      }

      .options {
        .btn+.btn {
          margin-left: 0.5rem;
        }
      }
    }

    .message-list {
      height: 100%;
      overflow-y: auto;
      padding: 2rem;

      .message-wrapper {
        display: flex;
        flex-direction: column;

        .message {
          max-width: 80%;
          width: max-content;
          display: flex;
          flex-direction: column;

          .speech-bubble {
            color: var(--hue1-black, #222);
            font-feature-settings: 'clig' off, 'liga' off;
            line-height: 20.5px;

            border-radius: 10px;
            border: 1px solid var(--bs-gray, #E7E7E7);
            background: #FFF;

            display: flex;
            padding: 13px;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }

          .time {
            color: var(--bs-gray, #E7E7E7);
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }
      }

      .message-wrapper.my-item {
        align-items: flex-end;
      }

      .message-wrapper+.message-wrapper {
        margin-top: 10px;
      }
    }

    .message-input-wrapper {
      width: 100%;
      display: flex;

      input {
        width: 100%;
        padding: 1rem;

        border-top: 1px solid var(--bs-gray, #E7E7E7);
        border-bottom: none;
        border-right: none;
        border-left: none;

        &:focus {
          outline: none;
        }
      }

      .btn {
        width: 200px;
        margin: 1rem;
      }
    }
  }

  .proudct-message {
    padding: 1rem 0.25rem;
    width: 320px;
    height: 130px;

    .thumbnail-wrapper {
      height: 100%;
      width: 100%;
    }

    .btn {
      padding: 0.25rem 0.75rem;
    }
  }

  .schedule-form {
    color: var(--bs-primary, #4E9A5E);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    border-radius: 30px;

    input {
      border-radius: 10px;
      border: 1px solid var(--bs-primary, #4E9A5E);
      background: var(--bs-white, #FFF);
      padding: 8px 10px 8px 13px;
    }

    textarea {
      border-radius: 20px;
      width: 305px;
      height: 161px;
      padding: 1rem;
      margin: 1.5rem;
      border: 1px solid var(--bs-primary, #4E9A5E);
      background: var(--bs-white, #FFF);
      box-shadow: 0px 1px 13.4px -2px rgba(78, 154, 94, 0.50);
    }


    .title {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .row {
      align-items: center;
    }

    .row+.row {
      margin-top: 11px;
    }
  }
}