.host-dashboard-section {
  display: flex;
  justify-content: center;

  .content-region {
    width: 88.581024349%;

    .calendar-wrapper {
      width: 69.19431279%;
      margin: 0 auto;
    }
  }

  .host-section {
    --content-height: 25rem;
    justify-content: space-between;
    align-items: center;

    >* {
      width: 45%;
    }

    .booking-inquery-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: var(--content-height);
      width: 100%;
      border: 1px solid var(--bs-gray);
      background: var(--bs-white);
      padding: 1.5rem 2rem;
    }

    .talking-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: var(--content-height);
      width: 100%;
      border: 1px solid var(--bs-gray);
      background: var(--bs-white);
      padding: 1.5rem 2rem;

      .talking-content {
        width: 100%;
        height: 100%;

        .talking-item {
          width: 8rem;
          height: 8rem;

          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          text-align: center;

          .thumbnail-wrapper {
            width: 6rem;
            height: 6rem;
          }

        }
      }
    }
  }
}