.search-bar {
  --search-button-width: 39px;

  border-radius: 50px;
  border: 2px solid var(--bs-primary);
  background: var(--bs-white);

  padding: 0.25rem 0.25rem 0.25rem 1rem;

  input {
    border: none;
    width: calc(100% - var(--search-button-width));

    &::placeholder {
      color: var(--bs-gray);
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  .btn {
    width: var(--search-button-width);
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    &:focus {
      border: none;
      outline: none;
    }
  }
}