.register-page {
    .page-content {
        .identity-content-wrapper {
            padding-top: 30px;
        }
    }

    .btn-secondary {
        width: 100px;
    }
}