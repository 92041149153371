.register-intro-page {
    .page-content {
        .identity-content-wrapper {
            padding-top: 140px;
            align-items: center;

            .register-intro-btn-group {
                button {
                    display: flex;
                    justify-content: space-between;
                    width: 340px;
                    padding: 27.5px 50px;
                }
            }
        }
    }
}