.profile-settings-section {
  display: flex;
  justify-content: center;

  .content-region {
    width: 88.581024349%;

    input {
      display: flex;
      padding: 22px 13px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .input-group {
      label {
        font-weight: 600;
      }
    }

    .image-input {
      width: 100%;
      height: 285px;
      color: #E7E7E7;
      cursor: pointer;

      .image-input-placeholder {
        svg+div {
          margin-top: 1rem;
        }
      }
    }

    .input-time-wrapper {
      width: 61.897356%
    }
  }
}