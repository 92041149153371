.thumbnail-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  width: inherit;
  height: inherit;

  .thumbnail {
    flex-shrink: 0;
    width: 100%;
    height: 100%;

    background-position: center;
    /* Make the background image cover the area of the <div>, and clip the excess */
    background-size: cover;
    
    background-repeat: no-repeat;
  }
}