.default-popup {
  width: 781px;
  height: 347px;

  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 18px 7px rgba(0, 0, 0, 0.10);
  display: block;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 5rem 1rem 5rem;

  .popup-content {
    border-radius: 10px;
    border: 1px solid var(--hue1-gray, #E7E7E7);
    background: #FFF;

    width: 100%;
    height: 75%;
  }

  .accept-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    padding: 11px 10px;
    height: 35px;
    width: 87px;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
  }
}