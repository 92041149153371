.popup-form.container {
  min-width: 900px;
  padding: 0;

  .popup-preview-wrapper {
    position: sticky;
    padding: 1rem;

    .popup-preview {
      display: flex;
      justify-content: center;
      align-items: center;

      img {        
        min-height: 100px;
        max-height: 90vh;
      }

      .image-input {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}