.send-email {
  .roles {
    label+label {
      margin-left: 1rem;
    }
  }

  .editor-wrapper {
    height: 80%;
  }

  .content-editor {
    height: 90%;
  }
}